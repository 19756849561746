/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
.standardTable :global .ant-table-pagination {
  margin-top: 24px;
}
.standardTable table > tbody > tr > td:first-child {
  text-align: left;
}
.standardTable .tableAlert {
  margin-bottom: 16px;
}
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
