/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.main {
  display: flex;
  width: 100%;
  height: 100%;
  padding-top: 16px;
  padding-bottom: 16px;
  overflow: auto;
  background-color: #fff;
}
.main .leftMenu {
  width: 224px;
  border-right: 1px solid #f0f0f0;
}
.main .leftMenu :global .ant-menu-inline {
  border: none;
}
.main .leftMenu :global .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
  font-weight: bold;
  border-right: 3px solid #1890ff;
}
.main .right {
  flex: 1;
  padding-top: 8px;
  padding-right: 40px;
  padding-bottom: 8px;
  padding-left: 40px;
}
.main .right .title {
  margin-bottom: 12px;
  color: rgba(0, 0, 0, 0.85);
  font-weight: 500;
  font-size: 20px;
  line-height: 28px;
}
.main :global .ant-list-split .ant-list-item:last-child {
  border-bottom: 1px solid #f0f0f0;
}
.main :global .ant-list-item {
  padding-top: 14px;
  padding-bottom: 14px;
}
:global .ant-list-item-meta .taobao {
  display: block;
  color: #ff4000;
  font-size: 48px;
  line-height: 48px;
  border-radius: 2px;
}
:global .ant-list-item-meta .dingding {
  margin: 2px;
  padding: 6px;
  color: #fff;
  font-size: 32px;
  line-height: 32px;
  background-color: #2eabff;
  border-radius: 2px;
}
:global .ant-list-item-meta .alipay {
  color: #2eabff;
  font-size: 48px;
  line-height: 48px;
  border-radius: 2px;
}
:global font.strong {
  color: #52c41a;
}
:global font.medium {
  color: #faad14;
}
:global font.weak {
  color: #ff4d4f;
}
@media screen and (max-width: 768px) {
  .main {
    flex-direction: column;
  }
  .main .leftMenu {
    width: 100%;
    border: none;
  }
  .main .right {
    padding: 40px;
  }
}
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
